
<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="No. Usulan">
                  <CInput
                    label="No. Usulan"
                    placeholder="No. Usulan"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    :disabled="true"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="required" v-slot="{ errors }" name="Tanggal Usulan">
                  <div role="group" class="form-group">
                    <label class>Tanggal Usulan</label>
                    <datepicker
                      placeholder="Tanggal Usulan"
                      v-model="form.tgl_surat"
                      :disabled="true"
                      :bootstrap-styling="true"
                      :input-class="'datePicker'"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></datepicker>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Instansi">
                  <div role="group" class="form-group">
                    <label class>Instansi</label>
                    <v-select
                      v-model="defaultSelected"
                      item-text="nama_instansi"
                      item-value="id_instansi"
                      disabled
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :filterable="false"
                      @search="onSearchInstansi"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider rules="required" v-slot="{ errors }" name="Unit Kerja">
                  <div role="group" class="form-group">
                    <label class>Unit Kerja</label>
                    <v-select
                      v-model="form.id_unit_kerja"
                      :options="optionsOPD"
                      label="nama_opd"
                      :reduce="opd => opd.id_opd"
                      :filterable="false"
                      :disabled="true"
                      @search="onSearchOPD"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <p style="font-size:10px;color:red;">
              <span style="font-style:italic;font-weight:bold;">*Note:</span>
              <ul>
                <li>Pendaftaran Anak maksimal diperbolehkan hanya 2 anak.</li>
                <li>Jika Istri / suami / anak sudah terdaftar harap menggunakan jenis SKUMPTK Update data keluarga</li>
              </ul>
            </p>
            <hr />
            <template v-for="(data, index) in form.details">
              <fieldset
                class="form-group border p-3"
                :key="index"
                v-if="data.is_deleted == 0"
              >
                <div small style :key="componentKey">
                  <CRow>
                    <CCol md="6">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        name="NIP - Nama"
                      >
                        <div role="group" class="form-group">
                          <label class>NIP - Nama</label>
                          <v-select
                            :key="'select_' + componentKey + index"
                            v-model="data.petugas_array"
                            :options="data.optionsPgw"
                            label="nama"
                            :disabled="true"
                            :filterable="false"
                            @search="onSearch(index, $event)"
                            @input="onChangesPegawai(index, $event)"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol md="6">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors }"
                        name="Jenis SKUMPTK"
                      >
                        <div role="group" class="form-group">
                          <label class>Jenis SKUMPTK</label>
                          <v-select
                            v-model="data.jenis_pelayanan_array"
                            :options="optionsJenisLayanan"
                            label="nama_layanan"
                            :filterable="true"
                            :disabled="!data.nip || true"
                            @input="onChangesJenisLayanan(index, $event)"
                            :class="[
                              { 'is-valid': !errors[0] },
                              { 'is-invalid': errors[0] },
                            ]"
                          ></v-select>
                          <div class="invalid-feedback" v-if="errors[0]">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </validation-provider>
                    </CCol>
                  </CRow>
                  <!---->
                  <fieldset
                    class="form-group border p-3"
                    v-if="data.informasi_pegawai"
                  >
                    <legend class="w-auto px-2">Informasi Pegawai</legend>

                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Jenis Kelamin"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.jenis_kelamin"
                        ></CInput>
                        <CInput
                          label="Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_jabatan"
                        ></CInput>
                        <CInput
                          label="Nama Unit Kerja"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.nama_unit_kerja"
                        ></CInput>
                        <CInput
                          label="Golongan Ruang"
                          placeholder
                          readonly
                          v-model="
                            data.informasi_pegawai.pangkat_golongan_ruang
                          "
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Gaji Pokok"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.gaji_pokok"
                        ></CInput>
                        <CInput
                          label="TMT Pangkat"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.tmt_pangkat"
                        ></CInput>
                        <CInput
                          label="Masa Jabatan"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.masa_jabatan"
                        ></CInput>
                        <CInput
                          label="Umur"
                          placeholder
                          readonly
                          v-model="data.informasi_pegawai.umur"
                        ></CInput>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CInput
                          label="Pendidikan Baru"
                          placeholder
                          readonly
                          :value="
                            data.pendidikan.length > 0
                              ? data.pendidikan[0]['nama_jurusan']
                              : ''
                          "
                        ></CInput>
                      </CCol>
                      <CCol md="6">
                        <CInput
                          label="Pendidikan Lama"
                          placeholder
                          readonly
                          :value="
                            data.pendidikan.length > 1
                              ? data.pendidikan[1]['nama_jurusan']
                              : ''
                          "
                        ></CInput>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <CTextarea
                          v-model="data.keterangan"
                          label="Keterangan"
                          rows="3"
                          :disabled="true"
                        />
                      </CCol>
                    </CRow>
                  </fieldset>
                  <div v-if="data.r_keluarga">
                    <div v-for="(dataKeluarga, index3) in data.r_keluarga" :key="index3">
                      <fieldset
                        class="form-group border p-3"
                        v-if="dataKeluarga.is_deleted == 0"
                      >
                        <legend class="w-auto px-2" v-if="data.ref_jenis_layanan_id == 16 || dataKeluarga.id_hubungan_keluarga == 21 || dataKeluarga.id_hubungan_keluarga == 4 || dataKeluarga.id_hubungan_keluarga == 5">Detail Pendaftaran Suami/Istri</legend>
                        <legend class="w-auto px-2" v-if="data.ref_jenis_layanan_id == 23">Detail Pendaftaran Anak ke-{{ index3+1 }}</legend>
                        <legend class="w-auto px-2" v-if="data.ref_jenis_layanan_id == 24 && (dataKeluarga.id_hubungan_keluarga == 31 || dataKeluarga.id_hubungan_keluarga == 1 || dataKeluarga.id_hubungan_keluarga == 2 || dataKeluarga.id_hubungan_keluarga == 3)">Detail Pendaftaran Anak ke-{{ index3 }}</legend>
                        <CRow>
                          <CCol md="6">
                            <validation-provider rules="required" v-slot="{ errors }" name="Nama Keluarga">
                              <CInput
                                label="Nama Keluarga"
                                placeholder="Nama Keluarga"
                                v-model="dataKeluarga.nama_keluarga"
                                :disabled="true"
                                :invalid-feedback="errors[0]"
                                :isValid="!errors[0]"
                              ></CInput>
                            </validation-provider>
                            <validation-provider rules="required" v-slot="{ errors }" name="Tempat Lahir">
                              <CInput
                                label="Tempat Lahir"
                                placeholder="Tempat Lahir"
                                v-model="dataKeluarga.tempat_lahir"
                                :disabled="true"
                                :invalid-feedback="errors[0]"
                                :isValid="!errors[0]"
                              ></CInput>
                            </validation-provider>
                            <validation-provider rules="required" v-slot="{ errors }" name="Tanggal Lahir">
                              <div role="group" class="form-group">
                                <label class>Tanggal Lahir</label>
                                <datepicker
                                  placeholder="Tanggal Lahir"
                                  v-model="dataKeluarga.tgl_lahir"
                                  :bootstrap-styling="true"
                                  :input-class="'datePicker'"
                                  :disabled="true"
                                  :class="[
                                    { 'is-valid': !errors[0] },
                                    { 'is-invalid': errors[0] },
                                  ]"
                                ></datepicker>
                                <div class="invalid-feedback" v-if="errors[0]">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </validation-provider>
                            <validation-provider v-if="data.ref_jenis_layanan_id == 16 || dataKeluarga.id_hubungan_keluarga == 21 || dataKeluarga.id_hubungan_keluarga == 4 || dataKeluarga.id_hubungan_keluarga == 5" rules="required" v-slot="{ errors }" name="Tanggal Kawin">
                              <div role="group" class="form-group">
                                <label class>Tanggal Kawin</label>
                                <datepicker
                                  placeholder="Tanggal Kawin"
                                  v-model="dataKeluarga.tgl_kawin"
                                  :bootstrap-styling="true"
                                  :input-class="'datePicker'"
                                  :disabled="true"
                                  :class="[
                                    { 'is-valid': !errors[0] },
                                    { 'is-invalid': errors[0] },
                                  ]"
                                ></datepicker>
                                <div class="invalid-feedback" v-if="errors[0]">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </validation-provider>
                          </CCol>
                          <CCol md="6">
                            <validation-provider rules="required" v-slot="{ errors }" name="Jenis Kelamin">
                              <div role="group" class="form-group">
                                <label class>Jenis Kelamin</label>
                                <v-select
                                  v-model="dataKeluarga.jenis_kelamin_array"
                                  :options="optionsJenisKelamin"
                                  label="text"
                                  :disabled="true"
                                  :filterable="true"
                                  @input="onChangesJenisKelamin(index, index3, $event)"
                                  :class="[
                                    { 'is-valid': !errors[0] },
                                    { 'is-invalid': errors[0] },
                                  ]"
                                ></v-select>
                                <div class="invalid-feedback" v-if="errors[0]">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </validation-provider>
                            <validation-provider rules="required" v-slot="{ errors }" name="Tingkat Pendidikan">
                              <div role="group" class="form-group">
                                <label class>Tingkat Pendidikan</label>
                                <v-select
                                  v-model="dataKeluarga.tingkat_pendidikan_array"
                                  :options="optionsTingkatPendidikan"
                                  label="nama_tingkat_pendidikan"
                                  :disabled="true"
                                  :filterable="true"
                                  @input="onChangesTingkatPendidikan(index, index3, $event)"
                                  :class="[
                                        { 'is-valid': !errors[0] },
                                        { 'is-invalid': errors[0] },
                                      ]"
                                ></v-select>
                                <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                              </div>
                            </validation-provider>
                            <validation-provider rules="required" v-slot="{ errors }" name="Pekerjaan">
                              <div role="group" class="form-group">
                                <label class>Pekerjaan</label>
                                <v-select
                                  v-model="dataKeluarga.pekerjaan_array"
                                  :options="optionsPekerjaan"
                                  label="nama_pekerjaan"
                                  :disabled="true"
                                  :filterable="true"
                                  @input="onChangesPekerjaan(index, index3, $event)"
                                  :class="[
                                        { 'is-valid': !errors[0] },
                                        { 'is-invalid': errors[0] },
                                      ]"
                                ></v-select>
                                <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                              </div>
                            </validation-provider>
                            <validation-provider rules="required" v-slot="{ errors }" name="Hubungan Keluarga">
                              <div role="group" class="form-group">
                                <label class>Hubungan Keluarga</label>
                                <v-select
                                  v-if="data.ref_jenis_layanan_id == 16 || dataKeluarga.id_hubungan_keluarga == 21 || dataKeluarga.id_hubungan_keluarga == 4 || dataKeluarga.id_hubungan_keluarga == 5"
                                  v-model="dataKeluarga.hubungan_keluarga_array"
                                  :options="optionsHubunganKeluargaIstriSuami"
                                  label="text"
                                  :disabled="true"
                                  :filterable="true"
                                  @input="onChangesHubunganKeluarga(index, index3, $event)"
                                  :class="[
                                    { 'is-valid': !errors[0] },
                                    { 'is-invalid': errors[0] },
                                  ]"
                                ></v-select>
                                <v-select
                                  v-if="data.ref_jenis_layanan_id == 23 || dataKeluarga.id_hubungan_keluarga == 31 || dataKeluarga.id_hubungan_keluarga == 1 || dataKeluarga.id_hubungan_keluarga == 2 || dataKeluarga.id_hubungan_keluarga == 3"
                                  v-model="dataKeluarga.hubungan_keluarga_array"
                                  :options="optionsHubunganKeluargaAnak"
                                  label="text"
                                  :disabled="true"
                                  :filterable="true"
                                  @input="onChangesHubunganKeluarga(index, index3, $event)"
                                  :class="[
                                    { 'is-valid': !errors[0] },
                                    { 'is-invalid': errors[0] },
                                  ]"
                                ></v-select>
                                <div class="invalid-feedback" v-if="errors[0]">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </validation-provider>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol>
                            <CTextarea
                              v-model="dataKeluarga.alamat_1"
                              label="Alamat"
                              rows="3"
                              :disabled="true"
                            />
                            <CTextarea
                              v-model="dataKeluarga.alamat_2"
                              label="Kecamatan"
                              rows="3"
                              :disabled="true"
                            />
                            <CTextarea
                              v-model="dataKeluarga.alamat_3"
                              label="Kabupaten/Kota"
                              rows="3"
                              :disabled="true"
                            />
                            <CTextarea
                              v-model="dataKeluarga.alamat_4"
                              label="Provinsi"
                              rows="3"
                              :disabled="true"
                            />
                          </CCol>
                        </CRow>
                      </fieldset>
                    </div>
                  </div>
                  <div class="position-relative table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>No</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>Jenis Dokumen</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>Format File</div>
                            <!---->
                          </th>
                          <th
                            class
                            style="vertical-align: middle; overflow: hidden"
                          >
                            <div>File Upload</div>
                            <!---->
                          </th>
                        </tr>
                        <!---->
                      </thead>
                      <tbody class="position-relative">
                        <tr
                          v-for="(dataUpload, index2) in data.upload_detail"
                          :key="index2"
                        >
                          <td>{{ index2 + 1 }}</td>
                          <td>{{ dataUpload.nama_dokumen }}</td>
                          <td>{{ dataUpload.format_file }}</td>
                          <td style="width: 20%">
                            <CInputFile
                              label="File input"
                              @change="uploadFile($event, index, index2)"
                              :isValid="true"
                              custom
                              v-if="
                                !dataUpload.upload_dokumen &&
                                !dataUpload.loadingFile
                              "
                            />
                            <CSpinner
                              color="success"
                              size="sm"
                              v-if="dataUpload.loadingFile"
                            />
                            <CButtonGroup
                              size="sm"
                              v-if="dataUpload.upload_dokumen"
                            >
                              <CButton
                                color="success"
                                class="px-4"
                                @click="downloadFile(index, index2)"
                                >Download File</CButton
                              >
                            </CButtonGroup>
                          </td>
                        </tr>
                      </tbody>
                      <!---->
                    </table>
                    <!---->
                  </div>
                  <!---->
                </div>
              </fieldset>
            </template>
            <CRow>
              <CCol md="6">
                <CTextarea
                  label="Catatan"
                  :placeholder="formDisabled ? '' : 'Catatan'"
                  rows="3"
                  v-model="form.keterangan"
                  :disabled="formDisabled"
                />
                <CSelect
                  label="Pengesahan"
                  :options="optionsStatusPengesahan"
                  placeholder="Pilih Hasil Pengesahan"
                  :value.sync="form.status_hasil"
                  :disabled="formDisabled"
                />
              </CCol>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="No. SK">
                  <CInput
                    label="No. SK"
                    placeholder="No. SK"
                    v-model="form.skumptk_no_sk"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                    :disabled="formDisabled"
                  ></CInput>
                </validation-provider>
                <validation-provider rules="required" v-slot="{ errors }" name="Tanggal SK">
                  <div role="group" class="form-group">
                    <label class>Tanggal SK</label>
                    <datepicker
                      placeholder="Tanggal SK"
                      v-model="form.skumptk_tgl_sk"
                      :bootstrap-styling="true"
                      :input-class="'datePicker'"
                      :disabled="formDisabled"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></datepicker>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
                <validation-provider rules="required" v-slot="{ errors }" name="Narasi">
                  <div role="group" class="form-group">
                    <CTextarea
                      label="Narasi"
                      placeholder="PP No 7 Tahun 1977 Pasal 16 Ayat 2 dan 3, dan ...."
                      rows="3"
                      v-model="form.skumptk_pp_no_7"
                      :disabled="formDisabled"
                    />
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CButton color="success" class="px-4 mr-2" @click="CloseForm()">
                  Kembali</CButton
                >
                <CSpinner color="success" size="sm" v-if="loading" />
                &nbsp;
                <CButton
                  class="px-4"
                  color="primary"
                  @click="pengesahan()"
                  v-if="!formDisabled"
                >Pengesahan</CButton>
              </CCol>
              <CCol col="6" class="text-right"></CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
  import Swal from "sweetalert2";
  import VSelect from "vue-select";
  import _ from "lodash";
  import Datepicker from "vuejs-datepicker";
  import axios from "axios";
  
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  export default {
    name: "FormPengesahanSKUMPTK",
    components: {
      VSelect,
      ValidationProvider,
      ValidationObserver,
      Datepicker,
    },
    data() {
      return {
        errorMessage: null,
        optionsPgw: [],
        optionsInstansi: [],
        optionsUnitKerja: [],
        optionsJenisLayanan: [],
        optionsOPD: [],
        optionsJenisKelamin:[
          { text: 'Laki-Laki', value: 1 },
          { text: 'Perempuan', value: 2 }
        ],
        optionsHubunganKeluargaIstriSuami:[
          { text: 'Suami', value: 4 },
          { text: 'Istri', value: 5 }
        ],
        optionsHubunganKeluargaAnak:[
          { text: 'Anak Kandung', value: 1 },
          { text: 'Anak Tiri', value: 2 },
          { text: 'Anak Angkat', value: 3 }
        ],
        optionsTingkatPendidikan: [],
        optionsPekerjaan: [],
        formVisibility: false,
        defaultSelected: {
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
        },
        optionsStatusPengesahan: [
          {
            value: 1,
            label: "Disetujui",
          },
          {
            value: 3,
            label: "Tidak Memenuhi Syarat",
          },
        ],
        form: {
          id: null,
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
          id_unit_kerja: null,
          id_opd: null,
          no_surat: null,
          tgl_surat: null,
          details: [],
          keterangan: null,
          status_hasil: null,
          skumptk_no_sk: null,
          skumptk_tgl_sk: null,
          skumptk_pp_no_7: null
        },
        loading: false,
        item: null,
        componentKey: 0,
        formDisabled: false
      };
    },
    computed: {},
    mounted() {},
    watch: {
      item: function (item) {
        this.form.id_instansi = 3214;
        this.form.id_unit_kerja = null;
        this.form.id_opd = null;
        this.form.nama_opd = null
        this.form.nip = null;
        this.form.no_surat = null;
        this.form.tgl_surat = null;
        this.optionsInstansi = [];
        this.optionsUnitKerja = [];
        this.optionsOPD = [];
        this.form.details = [];
        this.form.keterangan = null;
        this.form.status_hasil = null;
        this.form.skumptk_no_sk = null;
        this.form.skumptk_tgl_sk = null;
        this.form.skumptk_pp_no_7 = null;
        if (item) {
          this.form.id = item.id;
          this.form.id_instansi = item.id_instansi != null ? item.id_instansi.toString() : null;
          this.form.id_unit_kerja = item.id_unit_kerja;
          this.form.no_surat = item.no_surat;
          this.form.tgl_surat = item.tgl_surat;
          this.form.keterangan = item.keterangan;
          this.form.skumptk_no_sk = item.skumptk_no_sk;
          this.form.skumptk_tgl_sk = item.skumptk_tgl_sk;
          this.form.skumptk_pp_no_7 = item.skumptk_pp_no_7;
          const array = [];
          array.push(item.mst_instansi);
          this.optionsInstansi = array;
          const array2 = [];
          array2.push({
            id_opd: item.id_unit_kerja,
            nama_opd: item.nama_unit_kerja
          });
          this.optionsOPD = array2;
  
          item.pelayanan_detail.forEach((element) => {
            const arrayPegawai = [];
            arrayPegawai.push({
              nip: element.d_identitas.nip,
              nama: element.d_identitas.nip + " - " + element.d_identitas.nama,
            });
            this.form.details.push({
              id: element.id,
              nip: element.nip,
              ref_jenis_layanan_id: element.ref_jenis_layanan_id,
              ref_persyaratan_pelayanan_id: element.ref_persyaratan_pelayanan_id,
              upload_file: element.upload_file,
              jenis_pelayanan_array: element.ref_jenis_pelayanan,
              petugas_array: arrayPegawai,
              optionsPgw: arrayPegawai,
              informasi_pegawai: element.d_identitas.v_detail_informasi_pegawai,
              pendidikan: element.d_identitas.r_pendidikan_umum,
              keterangan: element.keterangan,
              upload_detail: element.pelayanan_detail_upload,
              is_deleted: 0,
              r_keluarga: element.pelayanan_r_keluarga,
              delete_r_keluarga: [],
            });
  
            element.pelayanan_r_keluarga.forEach((element2) => {
              var jenis_kelamin_array = this.optionsJenisKelamin.filter(jk => jk.value == element2.id_jenis_kelamin);
              element2["jenis_kelamin_array"] = jenis_kelamin_array[0];
              element2["tingkat_pendidikan_array"] = element2.tingkat_pendidikan;
              element2["tingkat_pendidikan"] = element2.tingkat_pendidikan.id_tingkat_pendidikan;
              element2["pekerjaan_array"] = element2.pekerjaan;
              if(element2.id_hubungan_keluarga == 4 || element2.id_hubungan_keluarga == 5){
                var hubungan_keluarga_array = this.optionsHubunganKeluargaIstriSuami.filter(val => val.value == element2.id_hubungan_keluarga);
                element2["hubungan_keluarga_array"] = hubungan_keluarga_array;
              }else{
                var hubungan_keluarga_array2 = this.optionsHubunganKeluargaAnak.filter(val => val.value == element2.id_hubungan_keluarga);
                element2["hubungan_keluarga_array"] = hubungan_keluarga_array2;
              }
              element2["is_deleted"] = 0;
            });
          });

          var index_tracking = item.tracking_layanan.length-1;
          if(item.tracking_layanan[index_tracking].status == 5){
            if(item.tracking_layanan[index_tracking].status_hasil != 0){
              this.form.status_hasil = item.tracking_layanan[index_tracking].status_hasil;
            }
            this.form.keterangan = item.tracking_layanan[index_tracking].keterangan;
          }
        }
      },
    },
    methods: {
      async uploadFile(files, index, index2) {
        const file = new FormData();
        file.append("files", files[0]);
        file.append("path", "dokumen-pegawai");
        file.append(
          "name_file",
          this.form.details[index]["upload_detail"][index2]["nama_dokumen"] +
            "_" +
            this.form.details[index]["nip"]
        );
        try {
          this.form.details[index]["upload_detail"][index2]["loadingFile"] = true;
          this.componentKey += 1;
          let { data } = await axios.post("/api/upload-document", file);
          this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
            data.url;
        } catch (error) {
          console.log(error);
          this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
            null;
        } finally {
          this.form.details[index]["upload_detail"][index2][
            "loadingFile"
          ] = false;
          this.componentKey += 1;
        }
      },
      async downloadFile(index, index2) {
        try {
          this.loading = true;
          var url = this.form.details[index]["upload_detail"][index2]["upload_dokumen"].split('/storage/');
          let response = await this.$store.dispatch("pelayanan/download", {
              path: url[1]
            }
          );
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            url[1].replace('dokumen-pegawai/', '')
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      deleteFile(index, index2) {
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
          null;
      },
      async onSearch(index, search) {
        await this.search(index, search, this);
      },
      search: _.debounce(async (index, search, vm) => {
        let options = await vm.$store.dispatch(
          "d_identitas/autocomplete",
          search
        );
        if (search) {
          requestAnimationFrame(() => {
            vm.form.details[index]["optionsPgw"] = options;
          });
        }
      }, 300),
      async searchLayanan(search) {
        let options = await this.$store.dispatch(
          "jenis_layanan/autoCompleteSearch",
          search
        );
        this.optionsJenisLayanan = options;
      },
      async pengesahan() {
        const vm = this;
        Swal.fire({
          title: "Peringatan!",
          text: "Apa Anda yakin ingin pengesahan data ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Pengesahan!",
        }).then(async (value) => {
          if(vm.form.status_hasil != null || vm.form.skumptk_no_sk != null){
            if (value.isConfirmed) {
              await this.$store.dispatch("pelayanan/pengesahan", {
                form: vm.form
              });
              Swal.fire({
                title: "Ok!",
                text: "Pengesahan Berhasil!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok!",
              }).then(() => {
                vm.componentKey += 1;
                requestAnimationFrame(() => {
                  this.$refs.observer.reset();
                });
                vm.$emit("done");
              });
            }
          }else{
            Swal.fire({
                title: "Ok!",
                text: "Harap melengkapi form!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok!",
            }).then(() => {
                vm.componentKey += 1;
            });
          }
        });
      },
      async submit() {
        const vm = this;
        try {
          this.loading = true;
          var form = { ...this.form };
          form.details.forEach((value) => {
            value.petugas_array = null;
            value.optionsPgw = null;
            value.informasi_pegawai = null;
          });
          let { status } = this.item
            ? await this.$store.dispatch("pelayanan/update", {
                data: form,
                id: vm.item.id,
              })
            : await this.$store.dispatch("pelayanan/store", form);
          if (status >= 200 && status <= 202) {
            Swal.fire({
              title: "Sukses",
              text: "Data berhasil tersimpan!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              if (!vm.item) {
                requestAnimationFrame(() => {
                  this.$refs.observer.reset();
                });
              }
              vm.$emit("done");
            });
          }
        } catch (x) {
          console.log(x);
        } finally {
          this.loading = false;
        }
      },
      showModalFormPilih(item) {
        this.$refs.formPilih.toggle(item, this.item);
      },
      populateInput(item, formDisabled) {
        (this.defaultSelected = {
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
        }),
        (this.form = {
          nama_instansi: "Purwakarta",
          id_instansi: 3214,
          id_unit_kerja: null,
          nip: null,
          id_opd: null,
          no_surat: null,
          tgl_surat: null,
          details: [],
          keterangan: null,
          status_hasil: null
        });
        this.searchLayanan("skumptk", this);
        this.onSearchTingkatPendidikan("a", this);
        this.onSearchPekerjaan("a", this);
        this.item = item;
        this.formDisabled = formDisabled
      },
      async onSearchInstansi(search, loading) {
        loading(true);
        await this.searchInstansi(search, this);
        loading(false);
      },
      searchInstansi: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "master_instansi/autocomplete",
          search
        );
        if (search) vm.optionsInstansi = options;
      }, 300),
      async onSearchUnitKerja(search, loading) {
        loading(true);
        await this.searchUnitKerja(search, this);
        loading(false);
      },
      searchUnitKerja: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
          search: search,
          id_instansi: vm.form.id_instansi,
          parent: "",
        });
        if (search) vm.optionsUnitKerja = options;
      }, 300),
      async onSearchOPD(search, loading) {
        loading(true);
        await this.searchOPD(search, this);
        loading(false);
      },
      searchOPD: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "master_unit_kerja/autocomplete_opd",
          search
        );
        if (search) vm.optionsOPD = options;
      }, 300),
      addDetails() {
        this.form.details.push({
          id: null,
          nip: null,
          ref_jenis_layanan_id: null,
          ref_persyaratan_pelayanan_id: null,
          upload_file: null,
          jenis_pelayanan_array: [],
          petugas_array: [],
          optionsPgw: [],
          informasi_pegawai: null,
          upload_detail: [],
          keterangan: null,
          pendidikan: null,
          is_deleted: 0,
          r_keluarga: [],
          delete_r_keluarga: [],
        });
      },
      deleteDetails(index) {
        this.form.details[index]["is_deleted"] = 1;
      },
      addRKeluarga(index) {
        this.form.details[index]["r_keluarga"].push({
          id: null,
          nama_keluarga: null,
          tempat_lahir: null,
          tgl_lahir: null,
          tgl_kawin: null,
          jenis_kelamin_array: [],
          id_jenis_kelamin: null,
          tingkat_pendidikan_array: [],
          tingkat_pendidikan: null,
          pekerjaan_array: [],
          id_pekerjaan: null,
          hubungan_keluarga_array: [],
          id_hubungan_keluarga: null,
          alamat_1: null,
          alamat_2: null,
          alamat_3: null,
          alamat_4: null,
          is_deleted: 0
        });
      },
      deleteRKeluarga(index, index3) {
        this.form.details[index].r_keluarga[index3].is_deleted = 1;
        this.form.details[index].delete_r_keluarga.push(
          this.form.details[index].r_keluarga[index3]
        );
        this.form.details[index].r_keluarga.splice(index3, 1);
      },
      onChangesJenisLayanan(index, value) {
        this.form.details[index].ref_jenis_layanan_id = value.id;
        const me = this;
  
        me.form.details[index]["upload_detail"] = [];
        value.ref_persyaratan_pelayanan.forEach(async (element) => {
          let document = await me.$store.dispatch(
            "d_identitas/document",
            {
              nip: me.form.details[index]['nip'],
              id: element.id
            }
          );
          me.form.details[index]["upload_detail"].push({
            id: null,
            upload_dokumen: document.file_path,
            ref_persyaratan_pelayanan_id: element.id,
            nama_dokumen: element.nama_dokumen,
            format_file: element.format_file,
            loadingFile: false,
          });
        });
  
        this.onDIRKeluarga(me.form.details[index]['nip'],index);
        this.componentKey += 1;
      },
      async onDIRKeluarga(search,index) {
        let options = await this.$store.dispatch(
          "d_r_keluarga/r_keluarga",
          {
            nip: search
          }
        );
        this.form.details[index]["r_keluarga"] = [];
        if(this.form.details[index].ref_jenis_layanan_id == 16){
          var dataIstriSuami = options.filter(val => val.id_hubungan_keluarga == 21);
          if(dataIstriSuami.length != 0){
            Swal.fire({
              title: "Peringatan!",
              text: "Data Istri/Suami sudah ada harap menggunakan Jenis SKUMPTK Update Data Keluarga!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              this.form.details[index].jenis_pelayanan_array = [];
              this.form.details[index].ref_jenis_layanan_id = null;
            });
          }else{
            this.form.details[index]["r_keluarga"].push({
              id: null,
              nama_keluarga: null,
              tempat_lahir: null,
              tgl_lahir: null,
              tgl_kawin: null,
              jenis_kelamin_array: [],
              id_jenis_kelamin: null,
              tingkat_pendidikan_array: [],
              tingkat_pendidikan: null,
              pekerjaan_array: [],
              id_pekerjaan: null,
              hubungan_keluarga_array: [],
              id_hubungan_keluarga: null,
              alamat_1: null,
              alamat_2: null,
              alamat_3: null,
              alamat_4: null,
              is_deleted: 0
            });
          }
        }else if(this.form.details[index].ref_jenis_layanan_id == 23){
          var dataAnak = options.filter(val => val.id_hubungan_keluarga == 31);
          if(dataAnak.length != 0){
            Swal.fire({
              title: "Peringatan!",
              text: "Data Anak sudah ada harap menggunakan Jenis SKUMPTK Update Data Keluarga!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Tutup!",
            }).then(() => {
              this.form.details[index].jenis_pelayanan_array = [];
              this.form.details[index].ref_jenis_layanan_id = null;
            });
          }else{
            this.form.details[index]["r_keluarga"].push({
              id: null,
              nama_keluarga: null,
              tempat_lahir: null,
              tgl_lahir: null,
              tgl_kawin: null,
              jenis_kelamin_array: [],
              id_jenis_kelamin: null,
              tingkat_pendidikan_array: [],
              tingkat_pendidikan: null,
              pekerjaan_array: [],
              id_pekerjaan: null,
              hubungan_keluarga_array: [],
              id_hubungan_keluarga: null,
              alamat_1: null,
              alamat_2: null,
              alamat_3: null,
              alamat_4: null,
              is_deleted: 0
            });
          }
        }else{
          var r_keluarga_array = [];
          for (let index2 = 0; index2 < 3; index2++) {
            var jenis_kelamin_array = this.optionsJenisKelamin.filter(jk => jk.value == options[index2].id_jenis_kelamin);
            options[index2]["jenis_kelamin_array"] = jenis_kelamin_array[0];
  
            var tingkat_pendidikan_array = this.optionsTingkatPendidikan.filter(tp => tp.nama_tingkat_pendidikan == options[index2].tingkat_pendidikan);
            options[index2]["tingkat_pendidikan_array"] = tingkat_pendidikan_array[0];
            options[index2]["tingkat_pendidikan"] = tingkat_pendidikan_array[0].id_tingkat_pendidikan;
  
            var pekerjaan_array = this.optionsPekerjaan.filter(pekerjaan => pekerjaan.id_pekerjaan == options[index2].id_pekerjaan);
            options[index2]["pekerjaan_array"] = pekerjaan_array[0];
  
            if(options[index2].id_hubungan_keluarga == 31){
              options[index2]["tgl_kawin"] = null;
            }
            options[index2]["hubungan_keluarga_array"] = [];
            options[index2]["is_deleted"] = 0;
  
            r_keluarga_array.push(options[index2]);
          }
          this.form.details[index]["r_keluarga"] = r_keluarga_array;
        }
      },
      onChangesPegawai(index, value) {
        this.form.details[index].informasi_pegawai = value.v_detail_informasi_pegawai;
        this.form.details[index].pendidikan = value.r_pendidikan_umum;
        this.form.details[index].nip = value.nip;
        this.componentKey += 1;
      },
      CloseForm() {
        this.$emit("clicked", !this.formVisibility);
      },
      onChangesJenisKelamin(index, index3, value) {
        this.form.details[index].r_keluarga[index3].id_jenis_kelamin = value.value;
      },
      onChangesHubunganKeluarga(index, index3, value) {
        this.form.details[index].r_keluarga[index3].id_hubungan_keluarga = value.value;
      },
      async onSearchTingkatPendidikan(search) {
        let options = await this.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_tingkat_pendidikan",
          search
        );
        this.optionsTingkatPendidikan = options;
      },
      onChangesTingkatPendidikan(index, index3, value) {
        this.form.details[index].r_keluarga[index3].tingkat_pendidikan = value.id_tingkat_pendidikan;
      },
      async onSearchPekerjaan(search) {
        let options = await this.$store.dispatch(
          "master_pekerjaan/autocomplete",
          search
        );
        this.optionsPekerjaan = options;
      },
      onChangesPekerjaan(index, index3, value) {
        this.form.details[index].r_keluarga[index3].id_pekerjaan = value.id_pekerjaan;
      },
    },
  };
</script>